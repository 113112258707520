import React from "react";

const SocialShare = [
  { iconName: "fa fa-linkedin", link: "https://pe.linkedin.com/in/gchacaltanab" },
  { iconName: "fa fa-github",link: "http://github.com/gchacaltana"},
  { iconName: "fa fa-code", link: "https://solocodigoweb.com/" },
  { iconName: "fa fa-database", link: "https://solodatascience.com/" },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            <i className={val.iconName}></i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
