import React from "react";

const posts = [
  {
    url: "https://solocodigoweb.com/blog/2024/09/23/pruebas-unitarias-y-mocking-en-el-desarrollo-de-software/",
    image: "img/blog/pruebas-unitarias-mocking-software.jpg",
    title: "Pruebas Unitarias y Mocking en el desarrollo de software"
  },
  {
    url: "https://solocodigoweb.com/blog/2024/08/30/fundamentos-de-la-arquitectura-basada-en-eventos/",
    image: "img/blog/fundamentos_arquitectura_basada_eventos_eda_solocodigoweb.jpg",
    title: "Fundamentos de la Arquitectura Basada en Eventos"
  },
  {
    url: "https://solocodigoweb.com/blog/2024/09/06/el-patron-de-mensajeria-messages-queue-en-una-arquitectura-basada-en-eventos/",
    image: "img/blog/patrones_mensajeria_colas_mensajes_solocodigoweb.jpg",
    title: "El Patrón Messages Queue en una Arquitectura basada en Eventos"
  },
  {
    url: "https://solocodigoweb.com/blog/2024/08/27/la-guia-definitiva-para-dominar-tu-proxima-entrevista-tecnica-en-sql/",
    image: "img/blog/solocodigoweb_guia_definitiva_entrevista_tecnica_sql.jpg",
    title: "La Guía Definitiva para dominar tu próxima entrevista técnica en SQL"
  },
  {
    url: "https://solodatascience.com/el-punto-de-inflexion-que-desencadeno-la-carrera-por-la-inteligencia-artificial-en-china/",
    image: "img/blog/punto_inflexion_desarrollo_china_inteligencia_artificial.jpg",
    title: "El punto de inflexión que desencadenó la carrera por la Inteligencia Artificial en China"
  },
  {
    url: "https://solocodigoweb.com/blog/2024/05/04/como-medir-la-efectividad-y-eficiencia-de-su-proceso-ci-cd/",
    image: "img/blog/solocodigoweb_como_medir_eficiencia_integracion_entrega_continua.jpg",
    title: "Cómo medir la efectividad y eficiencia de su proceso CI/CD"
  },
  {
    url: "https://solocodigoweb.com/blog/2024/04/03/taller-javascript-temporizadores/",
    image: "img/blog/solocodigoweb_timers_javascript.jpg",
    title: "Temporizadores en Javascript"
  },
  {
    url: "https://solocodigoweb.com/blog/2023/12/10/conceptos-claves-en-el-diseno-y-escalabilidad-de-software/",
    image: "img/blog/solocodigoweb_software_engineer_planning_design_software.jpg",
    title: "Conceptos claves en el diseño y escalabilidad de Software"
  },
  {
    url: "https://solocodigoweb.com/blog/2023/10/24/historia-de-las-bases-de-datos-como-servicio-dbaas/",
    image: "img/blog//solocodigoweb_database_as_a_service.jpg",
    title: "Historia de las Bases de Datos como Servicio (DBaaS)"
  },
  {
    url: "https://solodatascience.com/tecnicas-y-modelos-de-mineria-de-datos-y-machine-learning-mas-utilizados-en-proyectos-de-smart-cities/",
    image: "img/blog/mineria_datos_machine_learning_smart_cities_solodatascience.jpeg",
    title: "Técnicas y Modelos de Minería de Datos y Machine Learning más utilizados en proyectos de Smart Cities"
  },
  {
    url: "https://solocodigoweb.com/blog/2020/05/04/taller-de-ejercicios-11-caracteristicas-y-funciones-muy-utilizadas-en-la-programacion-con-python/",
    image: "img/blog/scw_ejercicios_python_funciones_lambda.jpg",
    title: "Taller de Ejercicios: 11 características y funciones muy utilizadas en la programación con python"
  },
  {
    url: "https://solodatascience.com/data-storage-la-base-de-la-computacion-centrada-en-datos/",
    image: "img/blog/data_storage_data_center_solodatascience.jpg",
    title: "Data Storage: La base de la Computación Centrada en Datos"
  },
  {
    url: "https://solocodigoweb.com/blog/2019/05/29/programacion-un-arte-basado-en-la-logica-y-creatividad/",
    image: "img/blog/programacion_arte_logica_creatividad.jpg",
    title: "Programación, un arte basado en la lógica y creatividad"
  },
  {
    url: "https://solodatascience.com/solucion-a-condahttperror-http-000-connection-failed/",
    image: "img/blog/solucion-anaconda-conda_HTTP_Error_solodatascience.jpg",
    title: "Solución CondaHTTPError: HTTP 000 CONNECTION FAILED"
  },
  {
    url: "https://solodatascience.com/cual-es-la-habilidad-mas-importante-de-un-cientifico-de-datos/",
    image: "img/blog/habilidad_mas_importante_cientifico_datos_solocodigoweb.com_.jpg",
    title: "¿Cuál es la habilidad más importante de un científico de datos?"
  },
  {
    url: "https://solocodigoweb.com/blog/2017/05/22/el-poder-de-las-bases-de-datos-nosql/",
    image: "img/blog/Poder-Base-Datos-NoSQL.jpg",
    title: "El poder de las bases de datos NoSQL"
  },
  {
    url: "https://solocodigoweb.com/blog/2020/01/20/taller-pl-sql-bloques-anonimos-procedimientos-almacenados-funciones-y-triggers-sobre-una-base-de-datos-oracle-19c/",
    image: "img/blog/Taller_PLSQL_oracle_procedimientos_almacenados_funciones_triggers.jpg",
    title: "Taller PL/SQL: Bloques Anónimos, Procedimientos Almacenados, Funciones y Triggers sobre una base de datos Oracle 19C"
  },
  {
    url: "https://solocodigoweb.com/blog/2020/02/17/buenas-practicas-para-mejorar-la-performance-de-tu-servidor-de-base-de-datos-mysql-5-7/",
    image: "img/blog/Buenas-Practicas-Performance-MySQL-solocodigoweb.com_.jpg",
    title: "Buenas prácticas para mejorar la performance de tu servidor de base de datos MySQL 5.7"
  },
  {
    url: "https://solocodigoweb.com/blog/2018/09/07/buenas-practicas-para-administrar-la-base-de-datos-de-produccion/",
    image: "img/blog/buenas_practicas_administrar_base_datos_produccion.png",
    title: "Buenas prácticas para administrar la base de datos de producción"
  },
  {
    url: "https://solocodigoweb.com/blog/2017/05/10/la-evolucion-del-ingeniero-de-software/",
    image: "img/blog/hola-mundo-evolucion-ingeniero-software.jpg",
    title: "La evolución del Ingeniero de Software"
  },
  {
    url: "https://solocodigoweb.com/blog/2017/06/27/introduccion-al-diseno-de-una-api-http-parte-1-de-4-http-requests/",
    image: "img/blog/api-http-design-restful.png",
    title: "Introducción al diseño de una API HTTP (Parte 1 de 4) : HTTP Requests"
  },
  {
    url: "https://solocodigoweb.com/blog/2018/05/18/cuando-usar-cassandra-cuando-usar-mongodb/",
    image: "img/blog/cassandra-mongodb-solocodigoweb.jpg",
    title: "¿Cuándo usar Cassandra? ¿Cuándo usar MongoDB?"
  },
  {
    url: "https://solocodigoweb.com/blog/2018/08/12/por-que-deberia-utilizar-un-certificado-ssl-en-mi-sitio-web/",
    image: "img/blog/certificado_ssl_importencia_solocodigoweb.jpg",
    title: "¿Por qué debería utilizar un certificado SSL/TLS en mi sitio web?"
  },
  {
    url: "https://solocodigoweb.com/blog/2019/06/25/nuevas-funciones-y-especificaciones-javascript-anunciadas-en-el-google-i-o-2019/",
    image: "img/blog/nuevas_funciones_javascript_google_io_2019.jpeg",
    title: "Nuevas características JavaScript presentadas en el Google I/O 2019"
  },
  {
    url: "https://speakerdeck.com/gchacaltana/c-plus-plus-inteligencia-artificial",
    image: "img/blog/lenguaje_c_c++_inteligencia_artificial.jpg",
    title: "Lenguajes de Programación C / C++ - Inteligencia Artificial"
  },
  {
    url: "https://speakerdeck.com/gchacaltana/optimizacion-de-aplicaciones-web-con-base-de-datos-nosql-in-memory",
    image: "img/blog/optimizacion-aplicaciones-web-in-memory.jpg",
    title: "Optimización de aplicaciones web con base de datos NoSQL In-Memory"
  },
  {
    url: "https://solocodigoweb.com/blog/2017/01/30/quiero-ser-un-cientifico-de-datos-por-donde-empiezo/",
    image: "img/blog/quiero-ser-cientifico-datos.jpg",
    title: "Quiero ser un científico de datos ¿Por dónde empiezo?"
  },
  {
    url: "https://solocodigoweb.com/blog/2014/09/19/generando-password-seguros-bcryptblowfish-en-go/",
    image: "img/blog/programming-go.png",
    title: "Generando password seguros Bcrypt/Blowfish en GO"
  },
  {
    url: "https://solocodigoweb.com/blog/2016/09/15/cuando-usar-soap-cuando-usar-rest/",
    image: "img/blog/cuando_usar_soap_cuando_usar_rest.jpg",
    title: "¿Cuándo usar SOAP? ¿Cuándo usar REST?"
  },
  {
    url: "https://speakerdeck.com/gchacaltana/aprendiendo-redis-en-20-minutos",
    image: "img/blog/aprendiendo_redis_20_min.jpg",
    title: "Aprendiendo REDIS en 20 minutos"
  },
  {
    url: "https://solocodigoweb.com/blog/2014/04/09/consumiendo-servicios-del-api-red-hat-cloudforms-con-php/",
    image: "img/blog/api_redhat_cloudform_php.png",
    title: "Consumiendo servicios del API Red Hat Cloudforms con PHP"
  },
  {
    url: "https://solocodigoweb.com/blog/2019/09/14/solucion-para-commands-out-of-sync-you-cant-run-this-command-now-en-codeigniter/",
    image: "img/blog/error_bug_codeigniter_stored_procedure_solucion.jpg",
    title: "Solución para «Commands out of sync; you can’t run this command now» en CodeIgniter"
  },
  {
    url: "https://solocodigoweb.com/blog/2017/05/20/optimizar-operaciones-de-listas-en-python/",
    image: "img/blog/operaciones_con_listas_python.jpg",
    title: "Optimizar operaciones de listas en Python"
  },
  {
    url: "https://solocodigoweb.com/blog/2014/04/30/buenas-practicas-javascript/",
    image: "img/blog/buenas_practicas_programacion_javascript.jpg",
    title: "Buenas prácticas para programar con JavaScript"
  },
  {
    url: "https://solocodigoweb.com/blog/2014/04/16/triangulo-de-pascal-en-python/",
    image: "img/blog/triangulo_pascal_python.jpg",
    title: "Triángulo de Pascal en Python"
  },
  {
    url: "https://solocodigoweb.com/blog/2013/11/26/como-trabajar-con-sqlite-con-php/",
    image: "img/blog/sqlite.png",
    title: "Manipulando bases de datos SQLite con PHP"
  }
];

const PostList = (
  Object.keys(posts).map((key, index) =>
    <div key={index} className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
      <article className="post-container">
        <div className="post-thumb">
          <div className="d-block position-relative overflow-hidden">
            <a target={'_blank'} href={posts[key].url} rel="noreferrer">
              <img
                src={posts[key].image}
                className="img-fluid" alt={posts[key].title} />
            </a>
          </div>
        </div>
        {/* End .thumb */}
        <div className="post-content">
          <div className="entry-header">
            <a target={'_blank'} href={posts[key].url} rel="noreferrer">
              <h3>{posts[key].title}</h3>
            </a>
          </div>
        </div>
        {/* End .post-content */}
      </article>
    </div>)
)

const Blog = () => {
  return (
    <>
      {PostList}
    </>
  );
};

export default Blog;
