import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import HeroShowCV from "../../components/hero/HeroShowCV";
import HeroHideCV from "../../components/hero/HeroHideCV";
import Index from "../../components/about/index";
import Blog from "../../components/blog/Blog";

// Variable para mostrar u ocultar información del CV
const showCV = false;

const menuItem = [];
let tabPanelAbout = "";
let heroComponent = <HeroHideCV />;
menuItem.push({ icon: "fa-home", menuName: "Home" });

if (showCV) {
  menuItem.push({ icon: "fa-user", menuName: "About" });
  heroComponent = <HeroShowCV />;
  tabPanelAbout = <TabPanel className="about">
    <div data-aos="fade-up" data-aos-duration="1200">
      <div className="title-section text-left text-sm-center">
        <h1>
          ABOUT <span>ME</span>
        </h1>
        <span className="title-bg">RESUME</span>
      </div>
      {/* End title */}
      <Index />
    </div>
  </TabPanel>;
}
menuItem.push({ icon: "fa-book", menuName: "Blog" });

const HomeDark = () => {
  return (
    <div className="yellow">
      <Tabs>
        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Hero Content Starts */}
          <TabPanel className="home ">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              {heroComponent}
            </div>
          </TabPanel>
          {/* Hero Content Ends */}

          {/* About Content Starts */}
          {tabPanelAbout}
          {/* About Content Ends */}

          {/* Blog Content Starts */}
          <TabPanel className="blog">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>blog</span>
              </h1>
              <span className="title-bg">posts</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              {/*  Articles Starts  */}
              <div className="row pb-50">
                <Blog />
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Blog Content Ends */}
        </div>
      </Tabs>
    </div>
  );
};

export default HomeDark;
